import api from './axios'

export default {
  get(params) {
    return api.get('/work-cards', { params })
  },
  getOne(id) {
    return api.get(`/work-cards/${id}`)
  },
  save(data) {
    return api.post('/work-cards', data)
  },
  update(data) {
    return api.put(`/work-cards/${data._id}`, data)
  },
  delete(id) {
    return api.delete(`/work-cards/${id}`)
  }
}
