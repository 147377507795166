import React, { useContext, useEffect, useState } from 'react'
import { UserContext, UserContextConsumer } from './components/UserContext'
import Login from './components/Login'
import Dashboard from './components/Dashboard'
import api from './api/axios'
export default function App() {
  const [render, setRender] = useState(false)
  const { dispatch } = useContext(UserContext)
  useEffect(() => {
    try {
      const { token, email, permission } = JSON.parse(
        window.localStorage.getItem('auth')
      )
      api.defaults.headers.Authorization = token
      dispatch({
        type: 'setUser',
        payload: {
          email,
          permission
        }
      })
      dispatch({
        type: 'setToken',
        payload: token
      })
      dispatch({ type: 'setAuthenticate' })
    } catch (e) {
      dispatch({
        type: 'logout'
      })
    }
    setRender(!render)
  }, [])
  const logout = () => {
    dispatch({
      type: 'logout'
    })
  }
  return (
    <UserContextConsumer>
      {(user) => (!user.state.isAuthenticate ? <Login /> : <Dashboard />)}
    </UserContextConsumer>
  )
}
