import React, { useState } from 'react'

import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

const defaultState = {
  code: '',
  name: '',
  multiplier: ''
}

export default function ConfigActionInputs({ addAction }) {
  const [values, setValues] = useState({
    code: '',
    name: '',
    multiplier: ''
  })
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const handleSubmit = () => {
    addAction(values)
    setValues(defaultState)
  }
  return (
    <form noValidate autoComplete="off">
      <TextField
        type="number"
        label="Kod"
        margin="normal"
        value={values.code}
        onChange={handleChange('code')}
      />
      <TextField
        label="Nazwa"
        margin="normal"
        value={values.name}
        onChange={handleChange('name')}
      />
      <TextField
        type="number"
        label="Stawka"
        margin="normal"
        value={values.multiplier}
        onChange={handleChange('multiplier')}
      />
      <Fab onClick={handleSubmit} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
    </form>
  )
}
