import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

const defaultState = {
  firstName: '',
  lastName: ''
}

export default function ConfigWorkerInputs({ addWorker }) {
  const [values, setValues] = useState(defaultState)
  const handleChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }
  const handleSubmit = () => {
    addWorker(values)
    setValues(defaultState)
  }
  return (
    <form noValidate autoComplete="off">
      <TextField
        label="Imię"
        margin="normal"
        value={values.firstName}
        onChange={(e) => handleChange('firstName', e.target.value)}
      />
      <TextField
        label="Nazwisko"
        margin="normal"
        value={values.lastName}
        onChange={(e) => handleChange('lastName', e.target.value)}
      />
      <Fab onClick={handleSubmit} color="primary" aria-label="add">
        <AddIcon />
      </Fab>
    </form>
  )
}
