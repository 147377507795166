import React from 'react'
import TextField from '@material-ui/core/TextField'

export default function EditWorkerModal({ worker, onChange }) {
  return (
    <>
      <TextField
        label="Imię"
        margin="normal"
        value={worker.firstName}
        onChange={(e) => onChange('firstName', e.target.value)}
      />
      <TextField
        label="Nazwisko"
        margin="normal"
        value={worker.lastName}
        onChange={(e) => onChange('lastName', e.target.value)}
      />
    </>
  )
}
