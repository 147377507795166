import React, { useState, useContext } from 'react'

// import UI components
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import api from '../api/axios'

// import utils
import auth from '../api/auth'
import { UserContext } from './UserContext'

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(3)
  }
}))

const Login = ({ history }) => {
  const [credentials, setCredentials] = useState({
    password: '',
    email: ''
  })
  const [showError, setShowError] = useState(false)
  const { dispatch } = useContext(UserContext)
  const classes = useStyles()
  const handleInputChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.name]: event.target.value
    })
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const { data } = await auth.getSession(credentials)
      const { token, userName, email } = data
      window.localStorage.setItem('auth', JSON.stringify(data))
      dispatch({
        type: 'setUser',
        payload: {
          email,
          userName
        }
      })
      dispatch({
        type: 'setToken',
        payload: token
      })
      dispatch({ type: 'setAuthenticate' })
      api.defaults.headers.Authorization = token
      setShowError(false)
    } catch (e) {
      setShowError(true)
    }
  }
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3}>
        <Card>
          <CardHeader title="Logowanie" />
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                name="email"
                type="email"
                label="Email"
                placeholder="Adres email"
                required
                fullWidth
                onChange={handleInputChange}
              />
              <TextField
                name="password"
                type="password"
                label="Hasło"
                placeholder="Hasło"
                required
                fullWidth
                onChange={handleInputChange}
              />
              <Button
                className={classes.submitButton}
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
              >
                Zaloguj się
              </Button>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Login
