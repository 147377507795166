import React, { useState, useEffect } from 'react'
import EditActionForm from './EditActionForm'
import EditWorkerForm from './EditWorkerForm'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function EditModal({ editData, type, open, handleClose, update }) {
  const [data, setData] = useState(editData)
  useEffect(() => {
    console.log(editData)
    setData(editData)
  }, [editData])
  const updateData = () => {
    update(data)
  }
  const handleChange = (type, value) => {
    console.log(type, value)
    setData({
      ...data,
      [type]: value
    })
    console.log(data)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Edycja</DialogTitle>
      <DialogContent>
        {type === 'action' ? (
          <EditActionForm action={data} onChange={handleChange} />
        ) : (
          <EditWorkerForm worker={data} onChange={handleChange} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Zamknij
        </Button>
        <Button onClick={() => updateData()} color="secondary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  )
}
