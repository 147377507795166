import React, { createContext, useReducer } from 'react'

const initialState = {
  user: {},
  authToken: '',
  isAuthenticate: false
}

const reducer = (state, action) => {
  console.log(action)
  switch (action.type) {
    case 'setUser':
      return { ...state, user: action.payload }
    case 'logout':
      window.localStorage.removeItem('auth')
      return { state: initialState }
    case 'setToken':
      return { ...state, authToken: action.payload }
    case 'setAuthenticate':
      return { ...state, isAuthenticate: !state.isAuthenticate }
    default:
      return state
  }
}

const UserContext = createContext(initialState)

const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const UserContextConsumer = UserContext.Consumer

export { UserContext, UserContextProvider, UserContextConsumer }
