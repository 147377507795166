import api from './axios'

export default {
  getAll() {
    return api.get('/actions')
  },
  getByCode(code) {
    return api.get(`/actions/code/${code}`)
  },
  save(data) {
    return api.post('/actions', data)
  },
  update(data) {
    return api.put(`/actions/${data._id}`, data)
  },
  delete(id) {
    return api.delete(`/actions/${id}`)
  }
}
