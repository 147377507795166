import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

export default function EditActionModal({ action, onChange }) {
  useEffect(() => {
    console.log(action)
  }, [action])
  return (
    <>
      <TextField
        label="Kod"
        margin="normal"
        value={action.code}
        onChange={(e) => onChange('code', e.target.value)}
      />
      <TextField
        label="Nazwa"
        margin="normal"
        value={action.name}
        onChange={(e) => onChange('name', e.target.value)}
      />
      <TextField
        id="standard-number"
        label="Mnożnik"
        value={action.multiplier}
        onChange={(e) => onChange('multiplier', e.target.value)}
        type="number"
        InputLabelProps={{
          shrink: true
        }}
        margin="normal"
      />
    </>
  )
}
