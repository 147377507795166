import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import apiEmployees from '@/api/employees.js'
import apiActions from '@/api/actions.js'
import ConfigActions from './ConfigActions.js'
import ConfigWorkerInputs from './ConfigWorkerInputs'
import ConfigWorker from './ConfigWorker'
import ConfigActionInputs from './ConfigActionInputs'
import EditModal from './EditModal'
import swal from 'sweetalert2'

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     position: 'absolute',
//     width: 400,
//     backgroundColor: theme.palette.background.paper,
//     border: '2px solid #000',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3)
//   },
//   root: {
//     width: '100%',
//     // maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//     position: 'relative',
//     overflow: 'auto',
//     maxHeight: 600
//   },
//   listSection: {
//     backgroundColor: 'inherit'
//   },
//   ul: {
//     backgroundColor: 'inherit',
//     padding: 0
//   }
// }))

export default function Config() {
  const [workers, setWorkers] = useState([])
  const [actions, setActions] = useState([])

  const [workerModal, setWorkerModal] = useState(false)
  const [actionModal, setActionModal] = useState(false)

  const [editAction, setEditAction] = useState({
    code: '',
    name: '',
    multiplier: 0
  })
  const [editWorker, setEditWorker] = useState({
    firstName: '',
    lastName: ''
  })
  useEffect(() => {
    apiEmployees.getAll().then((res) => setWorkers(res.data))
    apiActions.getAll().then((res) => setActions(res.data))
  }, [])

  const switchModal = (type) => {
    switch (type) {
      case 'action':
        setActionModal(!actionModal)
        break
      case 'worker':
        setWorkerModal(!workerModal)
    }
  }

  // const workerModal = (worker) => {
  //   setEditWorker(worker)
  //   switchModal('worker')
  // }
  const openActionModal = (action) => {
    setEditAction(action)
    switchModal('action')
  }

  const openWorkerModal = (worker) => {
    setEditWorker(worker)
    switchModal('worker')
  }
  const addAction = (user) => {
    apiActions
      .save(user)
      .then(() => {
        swal.fire({
          title: 'Zapisano akcję',
          type: 'success'
        })
        apiActions.getAll().then((res) => setActions(res.data))
      })
      .catch(() => {
        swal.fire({
          title: 'Błąd przy dodaniu akcji',
          type: 'error'
        })
      })
  }
  const addWorker = (worker) => {
    apiEmployees
      .save(worker)
      .then(() => {
        swal.fire({
          title: 'Zapisano pracownika',
          type: 'success'
        })
        apiEmployees.getAll().then((res) => setWorkers(res.data))
      })
      .catch(() => {
        swal.fire({
          title: 'Błąd przy dodaniu pracownika',
          type: 'error'
        })
      })
  }

  const updateAction = (data) => {
    apiActions
      .update(data)
      .then(() => {
        swal.fire({
          title: 'Zaktualizowano akcję',
          type: 'success'
        })
        switchModal('action')
        apiActions.getAll().then((res) => setActions(res.data))
      })
      .catch(() => {
        swal.fire({
          title: 'Błąd przy aktualizacji akcji',
          type: 'error'
        })
      })
  }
  const updateWorker = (data) => {
    apiEmployees
      .update(data)
      .then(() => {
        swal.fire({
          title: 'Zaktualizowano pracownika',
          type: 'success'
        })
        switchModal('worker')
        apiEmployees.getAll().then((res) => setWorkers(res.data))
      })
      .catch(() => {
        swal.fire({
          title: 'Błąd przy aktualizacji pracownika',
          type: 'error'
        })
      })
  }
  const deleteAction = (id) => {
    apiActions
      .delete(id)
      .then(() => {
        swal.fire({
          title: 'Usunięto akcję',
          type: 'success'
        })
        apiActions.getAll().then((res) => setActions(res.data))
      })
      .catch(() => {
        swal.fire({
          title: 'Błąd przy usunięciu akcji',
          type: 'error'
        })
      })
  }
  const deleteWorker = (id) => {
    apiEmployees
      .delete(id)
      .then(() => {
        swal.fire({
          title: 'Usunięto pracownika',
          type: 'success'
        })
        apiEmployees.getAll().then((res) => setWorkers(res.data))
      })
      .catch(() => {
        swal.fire({
          title: 'Błąd przy usunięciu pracownika',
          type: 'error'
        })
      })
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {/* <h3>{values.firstName} {values.lastName}</h3>     */}
          <ConfigWorkerInputs addWorker={addWorker} />
          <ConfigWorker
            onEdit={openWorkerModal}
            workers={workers}
            onDelete={deleteWorker}
          />
          <EditModal
            open={workerModal}
            handleClose={() => switchModal('worker')}
            type="worker"
            update={updateWorker}
            editData={editWorker}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ConfigActionInputs addAction={addAction} />
          <ConfigActions
            onEdit={openActionModal}
            actions={actions}
            onDelete={deleteAction}
          />
          <EditModal
            open={actionModal}
            handleClose={() => switchModal('action')}
            type="action"
            update={updateAction}
            editData={editAction}
          />
        </Grid>
      </Grid>
    </div>
  )
}
