import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import { pl } from 'date-fns/locale/'
import Paper from '@material-ui/core/Paper'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MaterialTable from 'material-table'
import employeeApi from '../api/employees'
import workCardsApi from '../api/workCards'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { Edit as EditIcon } from '@material-ui/icons'
import EditWorkCard from './EditWorkCard'
import PrintIcon from '@material-ui/icons/Print'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 400
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

export default function SpanningTable({ history }) {
  const classes = useStyles()
  const [employees, setEmployees] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [filters, setFilters] = useState({
    datestart: moment().format('YYYY-MM-DD'),
    dateend: moment().format('YYYY-MM-DD'),
    employee: ''
  })
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({
    employee: '',
    date: '',
    actionName: '',
    actionCode: '',
    multiplier: 0,
    sum: 0
  })
  const columns = [
    {
      title: 'Akcje',
      field: '',
      render: (rowData) => (
        <>
          <Button onClick={() => editData(rowData)}>
            <EditIcon />
          </Button>
          <Button>
            <DeleteIcon onClick={() => deleteData(rowData._id)} />
          </Button>
        </>
      )
    },
    {
      title: 'Pracownik',
      field: 'employee'
    },
    {
      title: 'Data',
      field: 'date'
    },
    {
      title: 'Kod',
      field: 'actionCode'
    },
    {
      title: 'Nazwa akcji',
      field: 'actionName'
    },
    {
      title: 'Stawka',
      field: 'multiplier'
    },
    {
      title: 'Ilość',
      field: 'quantity'
    },
    {
      title: 'Suma',
      field: 'sum'
    }
  ]
  const options = {
    paging: false,
    search: false
  }
  const handleFilterChange = (type, data) => {
    setFilters({
      ...filters,
      [type]: data
    })
  }
  const fetchData = () => {
    workCardsApi
      .get(filters)
      .then((res) => {
        const { data } = res
        setDataTable(data)
      })
      .catch((e) => console.log(e))
  }
  useEffect(() => {
    employeeApi.getAll().then((res) => {
      const { data } = res
      setEmployees(data)
    })
  }, [])
  const switchModal = () => {
    setOpen(!open)
  }

  const editData = (data) => {
    setData(data)
    switchModal()
  }
  const deleteData = (id) => {
    workCardsApi.delete(id).then(() => fetchData())
  }
  return (
    <Paper className={classes.root}>
      <Grid container alignItems="baseline" className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="employee-input-id">Pracownik</InputLabel>
          <Select
            native
            value={filters.employee}
            onChange={(e) => handleFilterChange('employee', e.target.value)}
            inputProps={{
              name: 'employee',
              id: 'employee-input-id'
            }}
          >
            <option value=""></option>
            {employees.map((item) => (
              <option key={item._id} value={item.firstName + ' ' + item.lastName}>
                {item.firstName + ' ' + item.lastName}
              </option>
            ))}
          </Select>
          <FormHelperText>Wybierz pracownika</FormHelperText>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            label="Data od"
            margin="normal"
            id="date"
            value={filters.datestart}
            onChange={(data) => handleFilterChange('datestart', data)}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy-MM-dd"
            label="Data do"
            margin="normal"
            id="date"
            value={filters.dateend}
            onChange={(data) => handleFilterChange('dateend', data)}
          />
          <Button onClick={fetchData} color="primary">
            Szukaj
          </Button>
        </MuiPickersUtilsProvider>
        <Button color="primary" onClick={() => window.print()}>
          <PrintIcon />
        </Button>
      </Grid>
      <MaterialTable
        options={options}
        title="Lista kart pracy"
        columns={columns}
        data={dataTable.actions}
      />
      <p>Suma: {dataTable.workSum}</p>
      <EditWorkCard
        open={open}
        switchModal={switchModal}
        editData={data}
        onSave={fetchData}
      />
    </Paper>
  )
}
