import React, { useState, useEffect, useReducer, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import DateFnsUtils from '@date-io/date-fns'
import { pl } from 'date-fns/locale/'
import Card from '@material-ui/core/Card'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'
import Snackbar from '@material-ui/core/Snackbar'
import employeeApi from '../api/employees'
import actionApi from '../api/actions'
import workCardAPi from '../api/workCards'
import swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    textAlign: 'center'
  },
  card: {
    padding: '30px'
  },
  textField: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  sum: {
    textAlign: 'right'
  },
  span: {
    marginLeft: '30px',
    textAlign: 'center'
  }
}))

const actionReducer = (state, action) => {
  switch (action.type) {
    case 'addAll':
      return action.payload
    case 'add':
      state.push(action.payload)
      return state
    case 'remove':
      state.splice(action.payload, 1)
      return state
    case 'edit':
      state[action.payload.id] = action.payload.body
      console.log(state)
      return state
    case 'reset':
      return []
    case 'default':
      return state
  }
}

const AddWorkCards = () => {
  const [forceRerender, setForceRerender] = useState(false)
  const [sum, setSum] = useState(0)
  const [employees, setEmployees] = useState([])
  const [state, dispatch] = useReducer(actionReducer, [])
  const [employee, setEmployee] = useState('')
  const [date, setDate] = useState('')

  const classes = useStyles()
  useEffect(() => {
    employeeApi.getAll().then((res) => {
      const { data } = res
      setEmployees(data)
    })
  }, [])

  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value)
  }
  const handleDateChange = (date) => {
    setDate(date)
  }
  const sumCosts = () => {
    const value = state.reduce((prev, curr) => (prev += parseFloat(curr.sum)), 0)
    setSum(value.toFixed(2))
  }
  const addAction = () => {
    dispatch({
      type: 'add',
      payload: {
        actionCode: '',
        actionName: '',
        multiplier: 0,
        quantity: 0,
        sum: 0
      }
    })
    setForceRerender(!forceRerender)
  }
  const removeAction = (id) => {
    dispatch({
      type: 'remove',
      payload: id
    })
    setForceRerender(!forceRerender)
  }

  const changeActionCode = (index, value) => {
    actionApi
      .getByCode(value)
      .then((res) => {
        const { data } = res
        if (data) {
          const payload = {
            ...state[index],
            actionCode: data.code,
            actionName: data.name,
            multiplier: data.multiplier,
            quantity: 0
          }
          dispatch({
            type: 'edit',
            payload: {
              id: index,
              body: payload
            }
          })
          setForceRerender(!forceRerender)
          sumCosts()
        }
      })
      .catch(() => {
        dispatch({
          type: 'edit',
          payload: {
            id: index,
            body: {
              actionCode: '',
              actionName: '',
              multiplier: 0,
              quantity: 0,
              sum: 0
            }
          }
        })
        setForceRerender(!forceRerender)
        sumCosts()
      })
  }
  const changeActionQuantity = (index, value) => {
    const payload = state[index]
    payload.quantity = parseInt(value)
    payload.sum = parseFloat(payload.multiplier * payload.quantity).toFixed(2)
    dispatch({
      type: 'edit',
      payload: {
        id: index,
        body: payload
      }
    })
    sumCosts()
    setForceRerender(!forceRerender)
  }

  const saveWorkCard = () => {
    const payload = {
      employee,
      date,
      actions: state
    }
    if (
      payload.employee !== '' &&
      payload.date !== '' &&
      payload.actions.length > 0
    ) {
      workCardAPi
        .save(payload)
        .then(() => {
          swal.fire({
            title: 'Zapisano kartę pracy',
            type: 'success'
          })
          dispatch({
            type: 'reset'
          })
          setEmployee('')
          setDate('')
        })
        .catch(() => {
          swal.fire({
            title: 'Błąd przy aktualizacji pracownika',
            type: 'error'
          })
        })
    } else {
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Grid container spacing={2} justify="flex-start">
            <Grid item md={3} xs={12}>
              <FormControl>
                <InputLabel htmlFor="employee-input-id">Pracownik</InputLabel>
                <Select
                  native
                  fullWidth
                  value={employee}
                  onChange={handleEmployeeChange}
                  inputProps={{
                    name: 'employee',
                    id: 'employee-input-id'
                  }}
                >
                  <option value=""></option>
                  {employees.map((item) => (
                    <option
                      key={item._id}
                      value={item.firstName + ' ' + item.lastName}
                    >
                      {item.firstName + ' ' + item.lastName}
                    </option>
                  ))}
                </Select>
                <FormHelperText>Wybierz pracownika</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={3} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
                <KeyboardDatePicker
                  disableToolbar
                  fullWidth
                  variant="inline"
                  format="yyyy-MM-dd"
                  margin="normal"
                  id="date"
                  value={date}
                  onChange={handleDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={6} xs={12}>
              <h2 className={classes.sum}>Suma: {sum} zł</h2>
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" onClick={addAction}>
                Dodaj nową czynność
              </Button>
              <Button color="secondary" onClick={saveWorkCard}>
                Zapisz
              </Button>
              {state.map((item, i) => (
                <div className={classes.container} key={`list-${i}`}>
                  <TextField
                    className={classes.container}
                    value={item.actionCode}
                    onChange={async (e) => changeActionCode(i, e.target.value)}
                    placeholder="Kod czynności"
                    label="Kod czynności"
                  />
                  <Grid xs={3}>
                    <span className={classes.span}>Czynność: {item.actionName}</span>
                  </Grid>
                  <Grid xs={3}>
                    <span className={classes.span}>Mnożnik: {item.multiplier}</span>
                  </Grid>
                  <FormControl>
                    <TextField
                      label="Ilość"
                      placeholder="Ilość"
                      className={classes.container}
                      onChange={async (e) => changeActionQuantity(i, e.target.value)}
                      value={item.quantity}
                      type="number"
                    />
                  </FormControl>
                  <Grid>
                    <span className={classes.span}>{item.sum} zł</span>
                  </Grid>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => removeAction(i)}
                  >
                    <DeleteIcon className={classes.rightIcon} />
                  </Button>
                </div>
              ))}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
export default AddWorkCards
