import React, { useState, useEffect } from 'react'
import actionApi from '../api/actions'
import workCardsApi from '../api/workCards'
import employeeApi from '../api/employees'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import { pl } from 'date-fns/locale/'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    textAlign: 'left',
    width: '100%'
  },
  textField: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  span: {
    marginTop: theme.spacing(3),
    textAlign: 'left'
  }
}))

export default function EditWorCard({ open, switchModal, editData, onSave }) {
  const classes = useStyles()
  const [data, setData] = useState(editData)
  const [employees, setEmployees] = useState([])
  useEffect(() => {
    employeeApi.getAll().then((res) => {
      const { data } = res
      setEmployees(data)
    })
    console.log(editData)
    setData(editData)
  }, [editData])
  const saveData = () => {
    workCardsApi.update(data).then(() => {
      switchModal()
      onSave()
    })
  }
  const handleChange = (e) => (type) => {
    setData({
      ...data,
      [type]: e.target.value
    })
  }
  const changeActionQuantity = (value) => {
    const quantity = value
    const multiplier = data.multiplier
    const sum = parseFloat(multiplier * quantity).toFixed(2)
    setData({
      ...data,
      sum,
      quantity
    })
  }
  const handleDateChange = (date) => {
    setData({
      ...data,
      date
    })
  }
  const changeActionCode = (value) => {
    actionApi
      .getByCode(value)
      .then((res) => {
        const { data } = res
        console.log(data)
        if (data) {
          const payload = {
            ...data,
            actionCode: data.code,
            actionName: data.name,
            multiplier: data.multiplier
          }
          setData(payload)
        }
      })
      .catch(() => {
        setData({
          ...data,
          actionCode: '',
          actionName: '',
          multiplier: 0,
          quantity: 0
        })
      })
  }
  return (
    <Dialog
      open={open}
      onClose={switchModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Edycja</DialogTitle>
      <DialogContent>
        <div className={classes.container}>
          <FormControl>
            <Select
              fullWidth
              native
              value={data.employee}
              onChange={handleChange('employee')}
              inputProps={{
                name: 'employee',
                id: 'employee-input-id'
              }}
            >
              {employees.map((item) => (
                <option key={item._id} value={item.firstName + ' ' + item.lastName}>
                  {item.firstName + ' ' + item.lastName}
                </option>
              ))}
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={pl}
            className={classes.span}
          >
            <KeyboardDatePicker
              disableToolbar
              label="Wybierz datę"
              variant="inline"
              format="yyyy-MM-dd"
              id="date"
              value={data.date}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
          <TextField
            fullWidth
            className={classes.span}
            value={data.actionCode}
            onChange={async (e) => changeActionCode(e.target.value)}
            placeholder="Kod czynności"
            label="Kod czynności"
          />
          <Grid item xs={12} className={classes.span}>
            <span>Czynność: {data.actionName}</span>
          </Grid>
          <Grid item xs={12} className={classes.span}>
            <span>Mnożnik: {data.multiplier}</span>
          </Grid>
          <FormControl className={classes.span}>
            <TextField
              fullWidth
              label="Ilość"
              placeholder="Ilość"
              className={classes.container}
              onChange={async (e) => changeActionQuantity(e.target.value)}
              value={data.quantity}
              type="number"
            />
          </FormControl>
          <Grid item xs={12} className={classes.span}>
            <span>Suma: {data.sum} zł</span>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={switchModal} color="primary">
          Zamknij
        </Button>
        <Button onClick={saveData} color="secondary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  )
}
