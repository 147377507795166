import api from './axios'

export default {
  getAll() {
    return api.get('/employees')
  },
  save(data) {
    return api.post('/employees', data)
  },
  update(data) {
    return api.put(`/employees/${data._id}`, data)
  },
  delete(id) {
    return api.delete(`/employees/${id}`)
  }
}
